<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app style="border-right: 1px solid #c3bbbb">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 text-center">
            <img src="@/assets/logo.jpeg" alt="" width="100" />
            <h3 class="text-center">
              {{ $store.state.appName }} {{ $store.state.count }}
            </h3>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav class="menu">
        <v-list-item @click="dashBoard" link v-if="$can(['employee'])">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Navbar.dashboard") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyEmployee" :key="item.title" v-model="item.active"
            :prepend-icon="item.action" no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="employee_roster in item.route_group" @click="nextRouter(employee_roster.route)"
              :key="employee_roster.id" :to="employee_roster.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(employee_roster.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyEmployeeOT" :key="item.id" color="primary" :prepend-icon="item.action"
            no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(employee_ot, i) in item.route_group" @click="nextRouter(employee_ot.route)" :key="i"
              :to="employee_ot.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(employee_ot.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="employeeApproveOt" link v-if="lmActive == 'true' || hrActive == 'true'">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("employee_ot.approve_ot")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="employeeApproveUseOt" link v-if="lmActive == 'true' || hrActive == 'true'">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("employee_ot.approve_use_ot")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="empOtReport" link v-if="$can(['employee'])">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("ot_report.navbar")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyEmployeeEapproval" :key="item.title" v-model="item.active"
            :prepend-icon="item.action" no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-file-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="employee_approve in item.route_group" @click="nextRouter(employee_approve.route)"
              :key="employee_approve.id" :to="employee_approve.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(employee_approve.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyEmployeeTaskAllocation" :key="item.title" v-model="item.active"
            :prepend-icon="item.action" no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-file-send</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="employee_task_allocation in item.route_group"
              @click="nextRouter(employee_task_allocation.route)" :key="employee_task_allocation.id"
              :to="employee_task_allocation.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(employee_task_allocation.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyEmployeeMeeting" :key="item.title" v-model="item.active"
            :prepend-icon="item.action" no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="employee_meeting in item.route_group" @click="nextRouter(employee_meeting.route)"
              :key="employee_meeting.id" :to="employee_meeting.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(employee_meeting.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyEmployeeLeave" :key="item.title" v-model="item.active"
            :prepend-icon="item.action" no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-calendar-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="employee_leave in item.route_group" @click="nextRouter(employee_leave.route)"
              :key="employee_leave.id" :to="employee_leave.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(employee_leave.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="employeeApproveLeave" link v-if="lmActive == 'true'">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("empLeave.lineManagerApprove_nav")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="employeeHrApproveLeave" link v-if="hrActive == 'true'">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("empLeave.hrApprove_nav")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-if="$can(['employee'])">
          <v-list-group v-for="item in routeSynergyRecruitMent" :key="item.title" v-model="item.active"
            :prepend-icon="item.action" no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-account-search</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(item.name)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="recruitment in item.route_group" @click="nextRouter(recruitment.route)"
              :key="recruitment.id" :to="recruitment.to">
              <v-list-item-content>
                <v-list-item-title v-text="$t(recruitment.name)" style="font-size: 14px"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="select-lange" @click="toggleDropdown">
        <div class="icon--flag">
          <i class="fal fa-cog"></i>
        </div>
        <div class="container-select-language" :class="{ active: isToggleActive, hide: isToggleHide }">
          <div :class="{ 'drop--down-language': isToggleActive }" id="select--language">
            <div class="card--selected--language">
              <div class="language--flag">
                <div class="flag--section">
                  <p class="text-center user-name lfont text-primary">
                    {{ userInfo.name }}
                  </p>
                  <p class="text-center user-email lfont text-primary">
                    {{ userInfo.email }}
                  </p>
                </div>
                <div class="flag--section">
                  <div class="header---section">
                    {{ $t("Dashboard.menu.language") }}
                    <div class="content---section">
                      <ul>
                        <li v-for="locale in locales" :key="locale" @click="switchLocales(locale)">
                          <span class="icon--section">
                            <i class="far fa-language"></i>
                          </span>
                          <span class="section--title">
                            {{ localeNames[locale] }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flag--section">
                  <div class="header---section">
                    {{ $t("Dashboard.menu.setting") }}
                    <div class="content---section">
                      <ul>
                        <li class="mt-2" @click="changeNewPassword">
                          <span class="icon--section">
                            <i class="far fa-lock-alt"></i>
                          </span>
                          <span class="section--title">
                            {{ $t("Dashboard.menu.changePassword") }}
                          </span>
                        </li>
                        <li class="mt-2" @click="logoutUsers">
                          <span class="icon--section">
                            <i class="far fa-power-off icon--sign-out"></i>
                          </span>
                          <span class="section--title">
                            {{ $t("Dashboard.menu.sign out") }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modalChangePassword :visible="modalChangePassword" @change="(val) => (modalChangePassword = val)" />
    </v-app-bar>

    <div class="content">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import modalChangePassword from "@/views/changePassword/modalChangePassword";

export default {
  data: () => ({
    lmActive: false,
    hrActive: false,
    userInfo: JSON.parse(localStorage.getItem("synergy_user")),
    drawer: null,
    locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    localeNames: { en: "EN", la: "LA" },
    isToggleActive: false,
    isToggleHide: true,
    drawer: true,
    active: "",
    modalChangePassword: false,
    routeSynergyEmployee: [
      {
        id: "manage-user",
        icon: "fal fa-clipboard-user",
        name: "employee_roster.side_bar",
        route_group: [
          {
            id: "user-manage",
            name: "employee_roster.worksheet_schedule.side_bar",
            icon: "fal fa-user-circle",
            route: "employee_roster.index",
          },
        ],
      },
    ],

    routeSynergyEmployeeOT: [
      {
        id: "manage-ot",
        icon: "fal fa-clock-outline",
        name: "employee_ot.sidebar",
        route_group: [
          {
            id: "request-ot",
            name: "employee_ot.emp_nav",
            icon: "fal fa-user-circle",
            route: "employee_ot_request.index",
          },
          {
            id: "use-ot",
            name: "use_overtime.navbar",
            icon: "fal fa-user-circle",
            route: "employee_use_ot.index",
          },
        ],
      },
    ],
    routeSynergyEmployeeEapproval: [
      {
        id: "manage-e-approval",
        icon: "fal fa-file-check",
        name: "e-approve.navbar",
        route_group: [
          {
            id: "e-approve-add",
            name: "e-approve.navbar",
            icon: "fal fa-user-circle",
            route: "employee_e_approve.index",
          },
          {
            id: "e-approve",
            name: "e-approve.e-approve-nav",
            icon: "fal fa-user-circle",
            route: "employee_e_approve.approve",
          },
        ],
      },
    ],
    routeSynergyEmployeeTaskAllocation: [
      {
        id: "manage-task-allocation",
        icon: "fal fa-file-check",
        name: "taskAllocation.navbar",
        route_group: [
          {
            id: "assignment_task",
            name: "taskAllocation.assignment_nav",
            icon: "fal fa-user-circle",
            route: "employee_task_allocation.index",
          },
          {
            id: "my_Work",
            name: "taskAllocation.my_work_nav",
            icon: "fal fa-user-circle",
            route: "employee_task_allocation.mywork",
          },
          {
            id: "assigned_task",
            name: "taskAllocation.assigned_task_nav",
            icon: "fal fa-user-circle",
            route: "employee_task_allocation.assignment",
          },
        ],
      },
    ],
    routeSynergyEmployeeLeave: [
      {
        id: "manage-employee-leave",
        icon: "fal fa-file-check",
        name: "empLeave.navbar",
        route_group: [
          {
            id: "emp_meeting",
            name: "empLeave.history_nav",
            icon: "fal fa-user-circle",
            route: "employee_leave.index",
          },
        ],
      },
    ],
    routeSynergyRecruitMent: [
      {
        id: "recruitment",
        icon: "fal fa-file-check",
        name: "recruitment.title",
        route_group: [
          {
            id: "recruitment-request",
            name: "recruitment.request.navbar",
            icon: "fal fa-user-circle",
            route: "recruitment.request",
          },
          {
            id: "director-approve-recruitment",
            name: "director_approve_recruitment.director_approve.navbar",
            icon: "fal fa-user-circle",
            route: "director.approve.recruitment",
          },
          {
            id: "hr-approve-recruitment",
            name: "recruitment.hr_approve.navbar",
            icon: "fal fa-user-circle",
            route: "hr.approve.recruitment",
          },
          {
            id: "hr-selected-applicant",
            name: "recruitment.hr_selected_applicant.navbar",
            icon: "fal fa-user-circle",
            route: "hr.selected.applicant",
          },
          {
            id: "hr-selected-applicant-interview",
            name: "recruitment.hr_selected_applicant_interview.navbar",
            icon: "fal fa-user-circle",
            route: "hr.selected.applicant.interview",
          },
        ],
      },
    ],
    routeSynergyEmployeeMeeting: [
      {
        id: "manage-employee-meeting",
        icon: "fal fa-file-check",
        name: "meeting.navbar",
        route_group: [
          {
            id: "emp_new_meeting",
            name: "meeting.new_meeting_nav",
            icon: "fal fa-user-circle",
            route: "employee_meeting.index",
          },
          {
            id: "emp_history_meeting",
            name: "meeting.history_nav",
            icon: "fal fa-user-circle",
            route: "employee_meeting.history",
          },
        ],
      },
    ],
    right: null,
  }),
  components: {
    modalChangePassword,
  },
  methods: {
    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    ReportAbsence() {
      this.$router
        .push({
          name: "absenceReport.index",
        })
        .catch(() => { });
    },
    ReportEmpTax() {
      this.$router
        .push({
          name: "emTaxeport.index",
        })
        .catch(() => { });
    },
    permission(value) {
      const userRole = JSON.parse(localStorage.getItem("user_role"));
      /**
       *  tha user role pen superAdmin || CompanyAdmin man hai show all;
       */

      if (userRole === "superadmin" || userRole === "companyadmin") {
        return true;
      }
      if (value) {
        /**
         *
         * tha user me permission hai show menu in navbar;
         */
        const localPermission = JSON.parse(
          localStorage.getItem("userPermission")
        );
        return localPermission.some((el) => value.includes(el));
      }
    },

    nextRouter(route_name) {
      this.$router
        .push({
          name: route_name,
        })
        .catch(() => { });
    },
    menuLeave() {
      this.$router
        .push({
          name: "employee_leave.index",
        })
        .catch(() => { });
    },
    dashBoard() {
      this.$router
        .push({
          name: "EmployeeDashboard",
        })
        .catch(() => { });
    },
    employeeOt() {
      this.$router
        .push({
          name: "employee_ot_request.index",
        })
        .catch(() => { });
    },
    employeeEApproval() {
      this.$router
        .push({
          name: "employee_e_approve.index",
        })
        .catch(() => { });
    },
    employeeApproveOt() {
      this.$router
        .push({
          name: "employee_approve_ot.index",
        })
        .catch(() => { });
    },
    employeeApproveLeave() {
      this.$router
        .push({
          name: "employee_leave.approve",
        })
        .catch(() => { });
    },
    employeeHrApproveLeave() {
      this.$router
        .push({
          name: "employee_leave_hr.approve",
        })
        .catch(() => { });
    },
    employeeApproveUseOt() {
      this.$router
        .push({
          name: "employee_approve_use_ot.index",
        })
        .catch(() => { });
    },
    empUseOvertime() {
      this.$router
        .push({
          name: "employee_use_ot.index",
        })
        .catch(() => { });
    },
    empOtReport() {
      this.$router
        .push({
          name: "employee_report_ot.index",
        })
        .catch(() => { });
    },
    CompanyDashBoard() {
      this.$router
        .push({
          name: "CompanyDashboard",
        })
        .catch(() => { });
    },
    CompanyProfile() {
      this.$router.push({ name: "company.profile" }).catch(() => { });
    },
    logoutUsers() {
      this.LogoutUser().then((res) => {
        if (res) {
          setTimeout(() => {
            this.$emit("close");
            this.$emit("success");
            this.$notification.OpenNotification_Logout_Success(
              "top-right",
              "primary",
              3000
            );
          }, 300);
        }
      });
    },
    //function for call open dropdown in navbar
    toggleDropdown(e) {
      e.stopPropagation();
      this.isToggleHide = false;
      this.isToggleActive = !this.isToggleActive;
      this.$nextTick(() => {
        setTimeout(() => {
          this.isToggleHide = !this.isToggleActive;
        }, 100);
      });
    },
    //function for close dropdown
    closeDropDown() {
      this.isToggleHide = true;
      this.isToggleActive = false;
    },
    //function close drop down in navbar when we click body space empty
    registerOutsideClick() {
      document.body.addEventListener("click", this.closeDropDown);
    },
    destroyOutsideClick() {
      document.body.removeEventListener("click", this.closeDropDown);
    },
    //function for change language in web admin
    switchLocales(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        localStorage.setItem("Lang", locale);
      }
    },

    changeNewPassword() {
      this.modalChangePassword = true;
    },
    makeActive(item) {
      this.active = item;
    },
  },
  // computed:{
  //   currentRoute(){
  //     return this.$route.path
  //   }
  // },
  mounted() {
    this.registerOutsideClick();
  },
  beforeDestroy() {
    this.destroyOutsideClick();
  },
  created() {
    this.lmActive = localStorage.getItem("user_role_lm");
    this.hrActive = localStorage.getItem("user_role_hr");
  },
};
</script>

<style scoped lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}

.content {
  margin: 20px;
}

.menu {
  font-size: 14px !important;
}

.branding {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.image-logo {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background: #ffffff;

  img {
    width: 100px;
    height: 120px;
    margin-top: 8px;
    object-fit: contain;
  }

  h1 {
    text-transform: uppercase;
  }
}

.logout i {
  font-size: 24px;
  cursor: pointer;
  color: #e96464;
}

.select-lange {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  user-select: none;

  .icon--flag {
    font-size: 22px;
    margin-left: 20px;
    position: absolute;

    i {
      margin-right: 10px;
      color: #6e6d6d;
    }
  }
}

.container-select-language {
  width: 100%;
  margin-top: 270px;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.hide {
    visibility: hidden;
  }

  .drop--down-language {
    margin: 20px 0;
    float: right;
    background-color: white;
    box-shadow: 10px 10px 28px 0 rgb(0 0 0 / 20%);
    width: 260px;
    position: relative;
    border-radius: 8px;
    padding: 18px;
    transition: ease-out 0.2s;
  }

  .card--selected--language {
    width: 100%;
    height: auto;
    // background-color: #18BBEC;
    display: flex;

    .language--flag {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      //  background-color: red;

      .flag--section {
        width: 100%;
        height: auto;
        padding: 4px;
        //  background-color: yellow;

        .header---section {
          width: 100%;
          height: 100%;
          font-family: $font-family;

          .content---section {
            width: 100%;
            height: auto;
            //   background-color: blue;

            ul {
              width: 100%;
              height: 100%;
              //  background-color: #18BBEC;
              margin: 8px;
              padding: 0;
              cursor: pointer;

              .icon--section {
                margin-right: 10px;
                color: #696868;
                font-weight: 400;
              }

              .section--title {
                color: #696868;
                text-transform: capitalize;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
  }
}

.drop--down-language:after {
  bottom: 100%;
  left: 89%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 10px solid transparent;
  border-bottom-color: white;
  margin-left: -10px;
}

.sidebar-content {
  width: 100%;
  height: 550px;
  overflow: scroll;
  overflow-x: hidden;

  .sidebar-scroll {
    width: 400px;
    margin-top: 0;
  }
}

.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 30px;
  height: auto;
}

.sidebar-content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

.active {
  color: red;
  //background-color: #0a906c;
}

//@media screen and(max-width: 1024px) {
//  .sidebar-content {
//    height: 80vh;
//    overflow: scroll;
//    overflow-x: hidden;
//  }
//}
//
//@media screen and(max-width: 768px) {
//  .sidebar-content {
//    height: 80vh;
//    overflow: scroll;
//    overflow-x: hidden;
//  }
//}
</style>
